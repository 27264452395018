<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['dateOfBirth'])">
      <date-of-birth :value="dateOfBirth" @input="onFieldChange('dateOfBirth', $event)" />
    </question-with-error-wrap>

    <question-with-error-wrap :error="getFieldsError(['gender'])">
      <my-gender
        id="gender"
        class="my-gender q-mt32 q-mt40-md"
        :value="gender"
        @input="onFieldChange('gender', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap v-if="genotypeVisible" :error="getFieldsError(['genotype'])">
        <single-answer-question
          id="genotype"
          class="q-mt32 q-mt40-md"
          title="label.myGenotype"
          :value="genotype"
          :options="$options.genotypeOptions"
          @input="onFieldChange('genotype', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import DateOfBirth from '@/modules/questionnaire/components/steps/common/identity/demographics/DateOfBirth';
import MyGender from '@/modules/questionnaire/components/steps/common/identity/demographics/MyGender';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

export default {
  name: 'DemographicsTemplate',
  components: {
    FadeInTransitionWrap,
    DateOfBirth,
    MyGender,
    SingleAnswerQuestion,
    QuestionWithErrorWrap
  },
  mixins: [stepTemplateMixin],
  genotypeOptions: [
    { value: 'male', text: 'genotype.male' },
    { value: 'female', text: 'genotype.female' }
  ],
  props: {
    gender: {
      type: String,
      default: ''
    },
    dateOfBirth: {
      type: String,
      default: ''
    },
    genotype: {
      type: String,
      default: ''
    },
    genotypeVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
