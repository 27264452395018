<template>
  <div class="flex flex-column flex--align-center">
    <h2 class="u-typography-helvetica u-text u-text--s q-mb24 q-mt0">{{ title }}</h2>
    <div
      class="gender-options flex"
      role="radiogroup"
      tabindex="-1"
      :aria-label="title"
      data-question
      @keydown.down.prevent="onKeyboardNavigation('down')"
      @keydown.up.prevent="onKeyboardNavigation('up')"
    >
      <div
        v-for="{ gender, title: genderTitle } of $options.genderOptions"
        ref="option"
        :key="gender"
        class="gender-options__option option flex flex-column text-align-center q-fit"
        :class="getOptionClasses(gender)"
        role="radio"
        :tabindex="getOptionTabIndex({ gender })"
        :aria-checked="gender === value ? 'true' : 'false'"
        @click="onInput(gender)"
        @focus="onInput(gender)"
      >
        <img class="q-fit" :src="getImage(gender)" alt="" />
        <div class="q-mt8 u-typography-gilroy u-text u-text--xs flex flex--center--center">
          {{ $t(genderTitle) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clamp } from 'ramda';

const GENDER_OPTIONS = [
  { gender: 'female', title: 'gender.female' },
  { gender: 'male', title: 'gender.male' },
  { gender: 'other', title: 'gender.other' }
];

export default {
  name: 'MyGender',
  genderOptions: GENDER_OPTIONS,
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    title() {
      return this.$t('label.myGender');
    },
    selectedOptionIndex() {
      return GENDER_OPTIONS.findIndex(option => option.gender === this.value);
    }
  },
  methods: {
    getImage(gender) {
      return require(`@/modules/questionnaire/assets/images/gender-select/${gender}.svg`);
    },
    getOptionClasses(gender) {
      return {
        'gender-options__option--active': this.value === gender
      };
    },
    onInput(gender) {
      this.$emit('input', gender);
    },
    onKeyboardNavigation(direction) {
      const increment = direction === 'down' ? 1 : -1;
      const index = clamp(0, GENDER_OPTIONS.length - 1, this.selectedOptionIndex + increment);

      this.$refs.option[index].focus();
    },
    getOptionTabIndex(option) {
      if (!this.value) {
        return '0';
      }

      return option.gender === this.value ? '0' : '-1';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.gender-options {
  &__option {
    border: 1px solid var(--u-color-grey-200);
    border-radius: 10px;
    background-color: var(--u-color-white);
    flex: 1 1 auto;
    cursor: pointer;

    width: 90px;

    &:nth-child(2) {
      margin-right: 16px;
      margin-left: 16px;
    }

    &--active {
      border-color: var(--u-color-graphite);
      background-color: var(--u-color-grey-50);
    }
  }
}

.option {
  padding: 20px 24px;
  box-sizing: border-box;
}

@media (min-width: $tablet-start) {
  .gender-options {
    &__option {
      width: 111px;
    }
  }
}
</style>
